export default {
  "app.result.error.title": "提交失败",
  "app.result.error.description": "请核对并修改以下信息后，再重新提交。",
  "app.result.error.hint-title": "您提交的内容有如下错误：",
  "app.result.error.hint-text1": "您的账户已被冻结",
  "app.result.error.hint-btn1": "立即解冻",
  "app.result.error.hint-text2": "您的账户还不具备申请资格",
  "app.result.error.hint-btn2": "立即升级",
  "app.result.error.btn-text": "返回修改",
  "app.result.success.title": "提交成功",
  "app.result.success.description":
    "提交结果页用于反馈一系列操作任务的处理结果， 如果仅是简单操作，使用 Message 全局提示反馈即可。 本文字区域可以展示简单的补充说明，如果有类似展示 “单据”的需求，下面这个灰色区域可以呈现比较复杂的内容。",
  "app.result.success.operate-title": "项目名称",
  "app.result.success.operate-id": "项目 ID：",
  "app.result.success.principal": "负责人：",
  "app.result.success.operate-time": "生效时间：",
  "app.result.success.step1-title": "创建项目",
  "app.result.success.step1-operator": "曲丽丽",
  "app.result.success.step2-title": "部门初审",
  "app.result.success.step2-operator": "周毛毛",
  "app.result.success.step2-extra": "催一下",
  "app.result.success.step3-title": "财务复核",
  "app.result.success.step4-title": "完成",
  "app.result.success.btn-return": "返回列表",
  "app.result.success.btn-project": "查看项目",
  "app.result.success.btn-print": "打印",
};
