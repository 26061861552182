export default {
  "menu.home": "首页",
  "menu.dashboard": "Dashboard",
  "menu.dashboard.analysis": "分析页",
  "menu.dashboard.monitor": "监控页",
  "menu.dashboard.workplace": "工作台",
  "menu.form": "表单页",
  "menu.form.basicform": "基础表单",
  "menu.form.stepform": "分步表单",
  "menu.form.stepform.info": "分步表单（填写转账信息）",
  "menu.form.stepform.confirm": "分步表单（确认转账信息）",
  "menu.form.stepform.result": "分步表单（完成）",
  "menu.form.advancedform": "高级表单",
  "menu.list": "列表页",
  "menu.list.searchtable": "查询表格",
  "menu.list.basiclist": "标准列表",
  "menu.list.cardlist": "卡片列表",
  "menu.list.searchlist": "搜索列表",
  "menu.list.searchlist.articles": "搜索列表（文章）",
  "menu.list.searchlist.projects": "搜索列表（项目）",
  "menu.list.searchlist.applications": "搜索列表（应用）",
  "menu.profile": "详情页",
  "menu.profile.basic": "基础详情页",
  "menu.profile.advanced": "高级详情页",
  "menu.result": "结果页",
  "menu.result.success": "成功页",
  "menu.result.fail": "失败页",
  "menu.exception": "异常页",
  "menu.exception.not-permission": "403",
  "menu.exception.not-find": "404",
  "menu.exception.server-error": "500",
  "menu.exception.trigger": "触发错误",
  "menu.account": "个人页",
  "menu.account.center": "个人中心",
  "menu.account.settings": "个人设置",
  "menu.account.trigger": "触发报错",
  "menu.account.logout": "退出登录",
};
