export default {
  "app.login.userName": "用户名",
  "app.login.password": "密码",
  "app.login.message-invalid-credentials": "账户或密码错误（admin/ant.design）",
  "app.login.message-invalid-verification-code": "验证码错误",
  "app.login.tab-login-credentials": "账户密码登录",
  "app.login.tab-login-mobile": "手机号登录",
  "app.login.remember-me": "自动登录",
  "app.login.forgot-password": "忘记密码",
  "app.login.sign-in-with": "其他登录方式",
  "app.login.signup": "注册账户",
  "app.login.login": "登录",
  "app.register.register": "注册",
  "app.register.get-verification-code": "获取验证码",
  "app.register.sign-in": "使用已有账户登录",
  "app.register-result.msg": "你的账户：{email} 注册成功",
  "app.register-result.activation-email":
    "激活邮件已发送到你的邮箱中，邮件有效期为24小时。请及时登录邮箱，点击邮件中的链接激活帐户。",
  "app.register-result.back-home": "返回首页",
  "app.register-result.view-mailbox": "查看邮箱",
  "validation.email.required": "请输入邮箱地址！",
  "validation.email.wrong-format": "邮箱地址格式错误！",
  "validation.userName.required": "请输入用户名!",
  "validation.password.required": "请输入密码！",
  "validation.password.twice": "两次输入的密码不匹配!",
  "validation.password.strength.msg": "请至少输入 6 个字符。请不要使用容易被猜到的密码。",
  "validation.password.strength.strong": "强度：强",
  "validation.password.strength.medium": "强度：中",
  "validation.password.strength.short": "强度：太短",
  "validation.confirm-password.required": "请确认密码！",
  "validation.phone-number.required": "请输入手机号！",
  "validation.phone-number.wrong-format": "手机号格式错误！",
  "validation.verification-code.required": "请输入验证码！",
  "validation.title.required": "请输入标题",
  "validation.date.required": "请选择起止日期",
  "validation.goal.required": "请输入目标描述",
  "validation.standard.required": "请输入衡量标准",
};
