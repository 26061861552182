export default {
  "menu.home": "Início",
  "menu.dashboard": "Dashboard",
  "menu.dashboard.analysis": "Análise",
  "menu.dashboard.monitor": "Monitor",
  "menu.dashboard.workplace": "Ambiente de Trabalho",
  "menu.form": "Formulário",
  "menu.form.basicform": "Formulário Básico",
  "menu.form.stepform": "Formulário Assistido",
  "menu.form.stepform.info": "Formulário Assistido(gravar informações de transferência)",
  "menu.form.stepform.confirm": "Formulário Assistido(confirmar informações de transferência)",
  "menu.form.stepform.result": "Formulário Assistido(finalizado)",
  "menu.form.advancedform": "Formulário Avançado",
  "menu.list": "Lista",
  "menu.list.searchtable": "Tabela de Busca",
  "menu.list.basiclist": "Lista Básica",
  "menu.list.cardlist": "Lista de Card",
  "menu.list.searchlist": "Lista de Busca",
  "menu.list.searchlist.articles": "Lista de Busca(artigos)",
  "menu.list.searchlist.projects": "Lista de Busca(projetos)",
  "menu.list.searchlist.applications": "Lista de Busca(aplicações)",
  "menu.profile": "Perfil",
  "menu.profile.basic": "Perfil Básico",
  "menu.profile.advanced": "Perfil Avançado",
  "menu.result": "Resultado",
  "menu.result.success": "Sucesso",
  "menu.result.fail": "Falha",
  "menu.exception": "Exceção",
  "menu.exception.not-permission": "403",
  "menu.exception.not-find": "404",
  "menu.exception.server-error": "500",
  "menu.exception.trigger": "Disparar",
  "menu.account": "Conta",
  "menu.account.center": "Central da Conta",
  "menu.account.settings": "Configurar Conta",
  "menu.account.trigger": "Disparar Erro",
  "menu.account.logout": "Sair",
};
