export default {
  "app.setting.pagestyle": "整體風格設置",
  "app.setting.pagestyle.dark": "暗色菜單風格",
  "app.setting.pagestyle.light": "亮色菜單風格",
  "app.setting.content-width": "內容區域寬度",
  "app.setting.content-width.fixed": "定寬",
  "app.setting.content-width.fluid": "流式",
  "app.setting.themecolor": "主題色",
  "app.setting.themecolor.dust": "薄暮",
  "app.setting.themecolor.volcano": "火山",
  "app.setting.themecolor.sunset": "日暮",
  "app.setting.themecolor.cyan": "明青",
  "app.setting.themecolor.green": "極光綠",
  "app.setting.themecolor.daybreak": "拂曉藍（默認）",
  "app.setting.themecolor.geekblue": "極客藍",
  "app.setting.themecolor.purple": "醬紫",
  "app.setting.navigationmode": "導航模式",
  "app.setting.sidemenu": "側邊菜單布局",
  "app.setting.topmenu": "頂部菜單布局",
  "app.setting.fixedheader": "固定 Header",
  "app.setting.fixedsidebar": "固定側邊菜單",
  "app.setting.fixedsidebar.hint": "側邊菜單布局時可配置",
  "app.setting.hideheader": "下滑時隱藏 Header",
  "app.setting.hideheader.hint": "固定 Header 時可配置",
  "app.setting.othersettings": "其他設置",
  "app.setting.weakmode": "色弱模式",
  "app.setting.copy": "拷貝設置",
  "app.setting.copyinfo": "拷貝成功，請到 src/defaultSettings.js 中替換默認配置",
  "app.setting.production.hint":
    "配置欄只在開發環境用於預覽，生產環境不會展現，請拷貝後手動修改配置文件",
};
