/* eslint-disable import/no-mutable-exports */
let CURRENT = "NULL";
/**
 * use  authority or getAuthority
 * @param Authorized
 */
const renderAuthorize = Authorized => currentAuthority => {
  if (currentAuthority) {
    if (typeof currentAuthority === "function") {
      CURRENT = currentAuthority();
    }
    if (
      Object.prototype.toString.call(currentAuthority) === "[object String]" ||
      Array.isArray(currentAuthority)
    ) {
      CURRENT = currentAuthority;
    }
  } else {
    CURRENT = "NULL";
  }
  return Authorized;
};

export { CURRENT };
export default Authorized => renderAuthorize(Authorized);
