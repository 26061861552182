export default {
  "form.get-captcha": "Get Captcha",
  "form.captcha.second": "sec",
  "form.optional": " (optional) ",
  "form.submit": "Submit",
  "form.save": "Save",
  "form.email.placeholder": "Email",
  "form.password.placeholder": "Password",
  "form.confirm-password.placeholder": "Confirm password",
  "form.phone-number.placeholder": "Phone number",
  "form.verification-code.placeholder": "Verification code",
  "form.title.label": "Title",
  "form.title.placeholder": "Give the target a name",
  "form.date.label": "Start and end date",
  "form.date.placeholder.start": "Start date",
  "form.date.placeholder.end": "End date",
  "form.goal.label": "Goal description",
  "form.goal.placeholder": "Please enter your work goals",
  "form.standard.label": "Metrics",
  "form.standard.placeholder": "Please enter a metric",
  "form.client.label": "Client",
  "form.client.label.tooltip": "Target service object",
  "form.client.placeholder":
    "Please describe your customer service, internal customers directly @ Name / job number",
  "form.invites.label": "Inviting critics",
  "form.invites.placeholder": "Please direct @ Name / job number, you can invite up to 5 people",
  "form.weight.label": "Weight",
  "form.weight.placeholder": "Please enter weight",
  "form.public.label": "Target disclosure",
  "form.public.label.help": "Customers and invitees are shared by default",
  "form.public.radio.public": "Public",
  "form.public.radio.partially-public": "Partially public",
  "form.public.radio.private": "Private",
  "form.publicUsers.placeholder": "Open to",
  "form.publicUsers.option.A": "Colleague A",
  "form.publicUsers.option.B": "Colleague B",
  "form.publicUsers.option.C": "Colleague C",
};
