export default {
  "form.get-captcha": "獲取驗證碼",
  "form.captcha.second": "秒",
  "form.optional": "（選填）",
  "form.submit": "提交",
  "form.save": "保存",
  "form.email.placeholder": "郵箱",
  "form.password.placeholder": "至少6位密碼，區分大小寫",
  "form.confirm-password.placeholder": "確認密碼",
  "form.phone-number.placeholder": "手機號",
  "form.verification-code.placeholder": "驗證碼",
  "form.title.label": "標題",
  "form.title.placeholder": "給目標起個名字",
  "form.date.label": "起止日期",
  "form.date.placeholder.start": "開始日期",
  "form.date.placeholder.end": "結束日期",
  "form.goal.label": "目標描述",
  "form.goal.placeholder": "請輸入妳的階段性工作目標",
  "form.standard.label": "衡量標淮",
  "form.standard.placeholder": "請輸入衡量標淮",
  "form.client.label": "客戶",
  "form.client.label.tooltip": "目標的服務對象",
  "form.client.placeholder": "請描述妳服務的客戶，內部客戶直接 @姓名／工號",
  "form.invites.label": "邀評人",
  "form.invites.placeholder": "請直接 @姓名／工號，最多可邀請 5 人",
  "form.weight.label": "權重",
  "form.weight.placeholder": "請輸入",
  "form.public.label": "目標公開",
  "form.public.label.help": "客戶、邀評人默認被分享",
  "form.public.radio.public": "公開",
  "form.public.radio.partially-public": "部分公開",
  "form.public.radio.private": "不公開",
  "form.publicUsers.placeholder": "公開給",
  "form.publicUsers.option.A": "同事甲",
  "form.publicUsers.option.B": "同事乙",
  "form.publicUsers.option.C": "同事丙",
};
