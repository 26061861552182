export default {
  "menu.home": "Trang chủ",
  "menu.dashboard": "Dashboard",
  "menu.dashboard.analysis": "Analysis",
  "menu.dashboard.monitor": "Monitor",
  "menu.dashboard.workplace": "Workplace",
  "menu.form": "Form",
  "menu.form.basicform": "Basic Form",
  "menu.form.stepform": "Step Form",
  "menu.form.stepform.info": "Step Form(write transfer information)",
  "menu.form.stepform.confirm": "Step Form(confirm transfer information)",
  "menu.form.stepform.result": "Step Form(finished)",
  "menu.form.advancedform": "Advanced Form",
  "menu.list": "List",
  "menu.list.searchtable": "Search Table",
  "menu.list.basiclist": "Basic List",
  "menu.list.cardlist": "Card List",
  "menu.list.searchlist": "Search List",
  "menu.list.searchlist.articles": "Search List(articles)",
  "menu.list.searchlist.projects": "Search List(projects)",
  "menu.list.searchlist.applications": "Search List(applications)",
  "menu.profile": "Profile",
  "menu.profile.basic": "Basic Profile",
  "menu.profile.advanced": "Advanced Profile",
  "menu.result": "Result",
  "menu.result.success": "Success",
  "menu.result.fail": "Fail",
  "menu.exception": "Exception",
  "menu.exception.not-permission": "403",
  "menu.exception.not-find": "404",
  "menu.exception.server-error": "500",
  "menu.exception.trigger": "Trigger",
  "menu.account": "Account",
  "menu.account.center": "Account Center",
  "menu.account.settings": "Account Settings",
  "menu.account.change-password": "Đổi mật khẩu",
  "menu.account.trigger": "Trigger Error",
  "menu.account.logout": "Đăng xuất",
  "menu.Departments": "Phòng ban",
  "menu.Employees": "Nhân viên",
  "menu.Cards": "Thẻ ngân hàng",
  "menu.Services": "Dịch vụ quảng cáo",
  "menu.Projects": "Dự án",
  "menu.Exchanges": "Sàn bất động sản",
  "menu.Configurations": "Cài đặt hệ thống",
  "menu.Transactions": "Giao dịch",
  "menu.Campaigns": "Chiến dịch",
};
