export default {
  "app.login.userName": "賬戶",
  "app.login.password": "密碼",
  "app.login.message-invalid-credentials": "賬戶或密碼錯誤（admin/ant.design）",
  "app.login.message-invalid-verification-code": "驗證碼錯誤",
  "app.login.tab-login-credentials": "賬戶密碼登錄",
  "app.login.tab-login-mobile": "手機號登錄",
  "app.login.remember-me": "自動登錄",
  "app.login.forgot-password": "忘記密碼",
  "app.login.sign-in-with": "其他登錄方式",
  "app.login.signup": "註冊賬戶",
  "app.login.login": "登錄",
  "app.register.register": "註冊",
  "app.register.get-verification-code": "獲取驗證碼",
  "app.register.sign-in": "使用已有賬戶登錄",
  "app.register-result.msg": "妳的賬戶：{email} 註冊成功",
  "app.register-result.activation-email":
    "激活郵件已發送到妳的郵箱中，郵件有效期為24小時。請及時登錄郵箱，點擊郵件中的鏈接激活帳戶。",
  "app.register-result.back-home": "返回首頁",
  "app.register-result.view-mailbox": "查看郵箱",
  "validation.email.required": "請輸入郵箱地址！",
  "validation.email.wrong-format": "郵箱地址格式錯誤！",
  "validation.userName.required": "請輸入賬戶!",
  "validation.password.required": "請輸入密碼！",
  "validation.password.twice": "兩次輸入的密碼不匹配!",
  "validation.password.strength.msg": "請至少輸入 6 個字符。請不要使用容易被猜到的密碼。",
  "validation.password.strength.strong": "強度：強",
  "validation.password.strength.medium": "強度：中",
  "validation.password.strength.short": "強度：太短",
  "validation.confirm-password.required": "請確認密碼！",
  "validation.phone-number.required": "請輸入手機號！",
  "validation.phone-number.wrong-format": "手機號格式錯誤！",
  "validation.verification-code.required": "請輸入驗證碼！",
  "validation.title.required": "請輸入標題",
  "validation.date.required": "請選擇起止日期",
  "validation.goal.required": "請輸入目標描述",
  "validation.standard.required": "請輸入衡量標淮",
};
