export default {
  "app.settings.menuMap.basic": "Configurações Básicas",
  "app.settings.menuMap.security": "Configurações de Segurança",
  "app.settings.menuMap.binding": "Vinculação de Conta",
  "app.settings.menuMap.notification": "Mensagens de Notificação",
  "app.settings.basic.avatar": "Avatar",
  "app.settings.basic.change-avatar": "Alterar avatar",
  "app.settings.basic.email": "Email",
  "app.settings.basic.email-message": "Por favor insira seu email!",
  "app.settings.basic.nickname": "Nome de usuário",
  "app.settings.basic.nickname-message": "Por favor insira seu nome de usuário!",
  "app.settings.basic.profile": "Perfil pessoal",
  "app.settings.basic.profile-message": "Por favor insira seu perfil pessoal!",
  "app.settings.basic.profile-placeholder": "Breve introdução sua",
  "app.settings.basic.country": "País/Região",
  "app.settings.basic.country-message": "Por favor insira país!",
  "app.settings.basic.geographic": "Província, estado ou cidade",
  "app.settings.basic.geographic-message": "Por favor insira suas informações geográficas!",
  "app.settings.basic.address": "Endereço",
  "app.settings.basic.address-message": "Por favor insira seu endereço!",
  "app.settings.basic.phone": "Número de telefone",
  "app.settings.basic.phone-message": "Por favor insira seu número de telefone!",
  "app.settings.basic.update": "Atualizar Informações",
  "app.settings.security.strong": "Forte",
  "app.settings.security.medium": "Média",
  "app.settings.security.weak": "Fraca",
  "app.settings.security.password": "Senha da Conta",
  "app.settings.security.password-description": "Força da senha",
  "app.settings.security.phone": "Telefone de Seguraça",
  "app.settings.security.phone-description": "Telefone vinculado",
  "app.settings.security.question": "Pergunta de Segurança",
  "app.settings.security.question-description":
    "A pergunta de segurança não está definida e a política de segurança pode proteger efetivamente a segurança da conta",
  "app.settings.security.email": "Email de Backup",
  "app.settings.security.email-description": "Email vinculado",
  "app.settings.security.mfa": "Dispositivo MFA",
  "app.settings.security.mfa-description":
    "O dispositivo MFA não vinculado, após a vinculação, pode ser confirmado duas vezes",
  "app.settings.security.modify": "Modificar",
  "app.settings.security.set": "Atribuir",
  "app.settings.security.bind": "Vincular",
  "app.settings.binding.taobao": "Vincular Taobao",
  "app.settings.binding.taobao-description": "Atualmente não vinculado à conta Taobao",
  "app.settings.binding.alipay": "Vincular Alipay",
  "app.settings.binding.alipay-description": "Atualmente não vinculado à conta Alipay",
  "app.settings.binding.dingding": "Vincular DingTalk",
  "app.settings.binding.dingding-description": "Atualmente não vinculado à conta DingTalk",
  "app.settings.binding.bind": "Vincular",
  "app.settings.notification.password": "Senha da Conta",
  "app.settings.notification.password-description":
    "Mensagens de outros usuários serão notificadas na forma de uma estação de letra",
  "app.settings.notification.messages": "Mensagens de Sistema",
  "app.settings.notification.messages-description":
    "Mensagens de sistema serão notificadas na forma de uma estação de letra",
  "app.settings.notification.todo": "Notificação de To-do",
  "app.settings.notification.todo-description":
    "A lista de to-do será notificada na forma de uma estação de letra",
  "app.settings.open": "Aberto",
  "app.settings.close": "Fechado",
};
