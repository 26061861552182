export default {
  "app.analysis.test": "Gongzhuan No.{no} shop",
  "app.analysis.introduce": "Introduzir",
  "app.analysis.total-sales": "Vendas Totais",
  "app.analysis.day-sales": "Vendas do Dia",
  "app.analysis.visits": "Visitas",
  "app.analysis.visits-trend": "Tendência de Visitas",
  "app.analysis.visits-ranking": "Ranking de Visitas",
  "app.analysis.day-visits": "Visitas do Dia",
  "app.analysis.week": "Taxa Semanal",
  "app.analysis.day": "Taxa Diária",
  "app.analysis.payments": "Pagamentos",
  "app.analysis.conversion-rate": "Taxa de Conversão",
  "app.analysis.operational-effect": "Efeito Operacional",
  "app.analysis.sales-trend": "Tendência de Vendas das Lojas",
  "app.analysis.sales-ranking": "Ranking de Vendas",
  "app.analysis.all-year": "Todo ano",
  "app.analysis.all-month": "Todo mês",
  "app.analysis.all-week": "Toda semana",
  "app.analysis.all-day": "Todo dia",
  "app.analysis.search-users": "Pesquisa de Usuários",
  "app.analysis.per-capita-search": "Busca Per Capta",
  "app.analysis.online-top-search": "Mais Buscadas Online",
  "app.analysis.the-proportion-of-sales": "The Proportion Of Sales",
  "app.analysis.channel.all": "Tudo",
  "app.analysis.channel.online": "Online",
  "app.analysis.channel.stores": "Lojas",
  "app.analysis.sales": "Vendas",
  "app.analysis.traffic": "Tráfego",
  "app.analysis.table.rank": "Rank",
  "app.analysis.table.search-keyword": "Palavra chave",
  "app.analysis.table.users": "Usuários",
  "app.analysis.table.weekly-range": "Faixa Semanal",
};
