import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/home/bigads/bigads-cms-fe/src/pages/.umi/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user(.html)?',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user.html',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/login.html',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__User__Login" */ '../User/Login'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Login').default,
        exact: true,
      },
      {
        path: '/user/forgot-password.html',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__User__ForgotPassword" */ '../User/ForgotPassword'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../User/ForgotPassword').default,
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/bigads/bigads-cms-fe/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__MemberLayout" */ '../../layouts/MemberLayout'),
          LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/MemberLayout').default,
    Routes: [require('../Authorized').default],
    routes: [
      {
        path: '/index.html',
        redirect: '/departments',
        exact: true,
      },
      {
        path: '/',
        redirect: '/departments',
        exact: true,
      },
      {
        path: '/account/settings/change-password.html',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__User__ChangePassword" */ '../User/ChangePassword'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../User/ChangePassword').default,
        exact: true,
      },
      {
        path: '/departments.html',
        name: 'Departments',
        icon: 'apartment',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Departments__TableView" */ '../Departments/TableView'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Departments/TableView').default,
        authority: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER'],
        exact: true,
      },
      {
        path: '/employees.html',
        name: 'Employees',
        icon: 'usergroup-add',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Employees" */ '../Employees'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Employees').default,
        authority: ['ROLE_ADMIN'],
        exact: true,
      },
      {
        path: '/cards.html',
        name: 'Cards',
        icon: 'credit-card',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Cards" */ '../Cards'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Cards').default,
        authority: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER', 'ROLE_LEADER'],
        exact: true,
      },
      {
        path: '/ads-services.html',
        name: 'Services',
        icon: 'cloud-server',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Services" */ '../Services'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Services').default,
        authority: ['ROLE_ADMIN'],
        exact: true,
      },
      {
        path: '/projects.html',
        name: 'Projects',
        icon: 'project',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Projects" */ '../Projects'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Projects').default,
        authority: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER', 'ROLE_LEADER'],
        exact: true,
      },
      {
        path: '/exchanges.html',
        name: 'Exchanges',
        icon: 'insert-row-below',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Exchanges" */ '../Exchanges'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Exchanges').default,
        authority: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER'],
        exact: true,
      },
      {
        path: '/campaigns.html',
        name: 'Campaigns',
        icon: 'pie-chart',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Campaigns" */ '../Campaigns'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Campaigns').default,
        authority: [
          'ROLE_ADMIN',
          'ROLE_ACCOUNT_MANAGER',
          'ROLE_LEADER',
          'ROLE_USER',
        ],
        exact: true,
      },
      {
        path: '/transactions.html',
        name: 'Transactions',
        icon: 'transaction',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Transactions" */ '../Transactions'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Transactions').default,
        authority: [
          'ROLE_ADMIN',
          'ROLE_ACCOUNT_MANAGER',
          'ROLE_LEADER',
          'ROLE_USER',
        ],
        exact: true,
      },
      {
        path: '/configurations.html',
        name: 'Configurations',
        icon: 'setting',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Configurations" */ '../Configurations'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Configurations').default,
        authority: ['ROLE_ADMIN'],
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/home/bigads/bigads-cms-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
      {
        component: () =>
          React.createElement(
            require('/home/bigads/bigads-cms-fe/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    component: () =>
      React.createElement(
        require('/home/bigads/bigads-cms-fe/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
          .default,
        { pagesPath: 'src/pages', hasRoutesInConfig: true },
      ),
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
