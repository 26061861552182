export default {
  "app.monitor.trading-activity": "活动实时交易情况",
  "app.monitor.total-transactions": "今日交易总额",
  "app.monitor.sales-target": "销售目标完成率",
  "app.monitor.remaining-time": "活动剩余时间",
  "app.monitor.total-transactions-per-second": "每秒交易总额",
  "app.monitor.activity-forecast": "活动情况预测",
  "app.monitor.efficiency": "券核效率",
  "app.monitor.ratio": "跳出率",
  "app.monitor.proportion-per-category": "各品类占比",
  "app.monitor.fast-food": "中式快餐",
  "app.monitor.western-food": "西餐",
  "app.monitor.hot-pot": "火锅",
  "app.monitor.waiting-for-implementation": "Waiting for implementation",
  "app.monitor.popular-searches": "热门搜索",
  "app.monitor.resource-surplus": "资源剩余",
  "app.monitor.fund-surplus": "补贴资金剩余",
};
