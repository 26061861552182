export default {
  "app.setting.pagestyle": "Configuração de estilo da página",
  "app.setting.pagestyle.dark": "Dark style",
  "app.setting.pagestyle.light": "Light style",
  "app.setting.content-width": "Largura do conteúdo",
  "app.setting.content-width.fixed": "Fixo",
  "app.setting.content-width.fluid": "Fluido",
  "app.setting.themecolor": "Cor do Tema",
  "app.setting.themecolor.dust": "Dust Red",
  "app.setting.themecolor.volcano": "Volcano",
  "app.setting.themecolor.sunset": "Sunset Orange",
  "app.setting.themecolor.cyan": "Cyan",
  "app.setting.themecolor.green": "Polar Green",
  "app.setting.themecolor.daybreak": "Daybreak Blue (default)",
  "app.setting.themecolor.geekblue": "Geek Glue",
  "app.setting.themecolor.purple": "Golden Purple",
  "app.setting.navigationmode": "Modo de Navegação",
  "app.setting.sidemenu": "Layout do Menu Lateral",
  "app.setting.topmenu": "Layout do Menu Superior",
  "app.setting.fixedheader": "Cabeçalho fixo",
  "app.setting.fixedsidebar": "Barra lateral fixa",
  "app.setting.fixedsidebar.hint": "Funciona no layout do menu lateral",
  "app.setting.hideheader": "Esconder o cabeçalho quando rolar",
  "app.setting.hideheader.hint": "Funciona quando o esconder cabeçalho está abilitado",
  "app.setting.othersettings": "Outras configurações",
  "app.setting.weakmode": "Weak Mode",
  "app.setting.copy": "Copiar Configuração",
  "app.setting.copyinfo":
    "copiado com sucesso，por favor trocar o defaultSettings em src/models/setting.js",
  "app.setting.production.hint":
    "O painel de configuração apenas é exibido no ambiente de desenvolvimento, por favor modifique manualmente o",
};
