export default {
  "component.globalHeader.search": "Busca",
  "component.globalHeader.search.example1": "Exemplo de busca 1",
  "component.globalHeader.search.example2": "Exemplo de busca 2",
  "component.globalHeader.search.example3": "Exemplo de busca 3",
  "component.globalHeader.help": "Ajuda",
  "component.globalHeader.notification": "Notificação",
  "component.globalHeader.notification.empty": "Você visualizou todas as notificações.",
  "component.globalHeader.message": "Mensagem",
  "component.globalHeader.message.empty": "Você visualizou todas as mensagens.",
  "component.globalHeader.event": "Evento",
  "component.globalHeader.event.empty": "Você visualizou todos os eventos.",
  "component.noticeIcon.clear": "Limpar",
  "component.noticeIcon.cleared": "Limpo",
  "component.noticeIcon.empty": "Sem notificações",
  "component.noticeIcon.loaded": "Carregado",
  "component.noticeIcon.loading-more": "Carregar mais",
};
