export default {
  "app.result.error.title": "A Submissão Falhou",
  "app.result.error.description":
    "Por favor, verifique e modifique as seguintes informações antes de reenviar.",
  "app.result.error.hint-title": "O conteúdo que você enviou tem o seguinte erro:",
  "app.result.error.hint-text1": "Sua conta foi congelada",
  "app.result.error.hint-btn1": "Descongele imediatamente",
  "app.result.error.hint-text2": "Sua conta ainda não está qualificada para se candidatar",
  "app.result.error.hint-btn2": "Atualizar imediatamente",
  "app.result.error.btn-text": "Retornar para modificar",
  "app.result.success.title": "A Submissão foi um Sucesso",
  "app.result.success.description":
    'A página de resultados de envio é usada para fornecer os resultados de uma série de tarefas operacionais. Se for uma operação simples, use o prompt de feedback de Mensagem global. Esta área de texto pode mostrar uma explicação suplementar simples. Se houver um requisito semelhante para exibir "documentos", a área cinza a seguir pode apresentar um conteúdo mais complicado.',
  "app.result.success.operate-title": "Nome do Projeto",
  "app.result.success.operate-id": "ID do Projeto：",
  "app.result.success.principal": "Principal：",
  "app.result.success.operate-time": "Tempo efetivo：",
  "app.result.success.step1-title": "Criar projeto",
  "app.result.success.step1-operator": "Qu Lili",
  "app.result.success.step2-title": "Revisão preliminar do departamento",
  "app.result.success.step2-operator": "Zhou Maomao",
  "app.result.success.step2-extra": "Urge",
  "app.result.success.step3-title": "Revisão financeira",
  "app.result.success.step4-title": "Terminar",
  "app.result.success.btn-return": "Voltar a lista",
  "app.result.success.btn-project": "Ver projeto",
  "app.result.success.btn-print": "imprimir",
};
