export default {
  "form.get-captcha": "获取验证码",
  "form.captcha.second": "秒",
  "form.optional": "（选填）",
  "form.submit": "提交",
  "form.save": "保存",
  "form.email.placeholder": "邮箱",
  "form.password.placeholder": "至少6位密码，区分大小写",
  "form.confirm-password.placeholder": "确认密码",
  "form.phone-number.placeholder": "手机号",
  "form.verification-code.placeholder": "验证码",
  "form.title.label": "标题",
  "form.title.placeholder": "给目标起个名字",
  "form.date.label": "起止日期",
  "form.date.placeholder.start": "开始日期",
  "form.date.placeholder.end": "结束日期",
  "form.goal.label": "目标描述",
  "form.goal.placeholder": "请输入你的阶段性工作目标",
  "form.standard.label": "衡量标准",
  "form.standard.placeholder": "请输入衡量标准",
  "form.client.label": "客户",
  "form.client.label.tooltip": "目标的服务对象",
  "form.client.placeholder": "请描述你服务的客户，内部客户直接 @姓名／工号",
  "form.invites.label": "邀评人",
  "form.invites.placeholder": "请直接 @姓名／工号，最多可邀请 5 人",
  "form.weight.label": "权重",
  "form.weight.placeholder": "请输入",
  "form.public.label": "目标公开",
  "form.public.label.help": "客户、邀评人默认被分享",
  "form.public.radio.public": "公开",
  "form.public.radio.partially-public": "部分公开",
  "form.public.radio.private": "不公开",
  "form.publicUsers.placeholder": "公开给",
  "form.publicUsers.option.A": "同事甲",
  "form.publicUsers.option.B": "同事乙",
  "form.publicUsers.option.C": "同事丙",
};
