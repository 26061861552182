const roles = {
  admin: "ROLE_ADMIN",
  manager: "ROLE_ACCOUNT_MANAGER",
  leader: "ROLE_LEADER",
  user: "ROLE_USER",
  checkIfAdmin() {
    return window.loginData?.checkRoles([this.admin]) || false;
  },
  checkIfAccountManager() {
    return window.loginData?.checkRoles([this.manager]) || false;
  },
  checkIfLeader() {
    return window.loginData?.checkRoles([this.leader]) || false;
  },
  checkIfUser() {
    return window.loginData?.checkRoles([this.user]) || false;
  },
  checkIfAdminNManager() {
    return window.loginData?.checkRoles([this.admin, this.manager]) || false;
  },
};

window.roles = roles;
export default roles;
