export default {
  "app.monitor.trading-activity": "Atividade de Trading Real-time",
  "app.monitor.total-transactions": "Total de transações hoje",
  "app.monitor.sales-target": "Taxa de conclusão da meta de vendas",
  "app.monitor.remaining-time": "Tempo restante da atividade",
  "app.monitor.total-transactions-per-second": "Total de transações por segundo",
  "app.monitor.activity-forecast": "Previsão atual",
  "app.monitor.efficiency": "Eficiência",
  "app.monitor.ratio": "Relação",
  "app.monitor.proportion-per-category": "Proporção por categoria",
  "app.monitor.fast-food": "Fast food",
  "app.monitor.western-food": "Comida Ocidental",
  "app.monitor.hot-pot": "Hot pot",
  "app.monitor.waiting-for-implementation": "Aguardando implementação",
  "app.monitor.popular-searches": "Buscas populares",
  "app.monitor.resource-surplus": "Excedente de recursos",
  "app.monitor.fund-surplus": "Excedente do fundo",
  "app.exception.back": "Voltar a home",
};
