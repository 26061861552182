export default {
  "app.analysis.test": "Gongzhuan No.{no} shop",
  "app.analysis.introduce": "Introduce",
  "app.analysis.total-sales": "Total Sales",
  "app.analysis.day-sales": "Daily Sales",
  "app.analysis.visits": "Visits",
  "app.analysis.visits-trend": "Visits Trend",
  "app.analysis.visits-ranking": "Visits Ranking",
  "app.analysis.day-visits": "Daily Visits",
  "app.analysis.week": "WoW Change",
  "app.analysis.day": "DoD Change",
  "app.analysis.payments": "Payments",
  "app.analysis.conversion-rate": "Conversion Rate",
  "app.analysis.operational-effect": "Operational Effect",
  "app.analysis.sales-trend": "Stores Sales Trend",
  "app.analysis.sales-ranking": "Sales Ranking",
  "app.analysis.all-year": "All Year",
  "app.analysis.all-month": "All Month",
  "app.analysis.all-week": "All Week",
  "app.analysis.all-day": "All day",
  "app.analysis.search-users": "Search Users",
  "app.analysis.per-capita-search": "Per Capita Search",
  "app.analysis.online-top-search": "Online Top Search",
  "app.analysis.the-proportion-of-sales": "The Proportion Of Sales",
  "app.analysis.channel.all": "ALL",
  "app.analysis.channel.online": "Online",
  "app.analysis.channel.stores": "Stores",
  "app.analysis.sales": "Sales",
  "app.analysis.traffic": "Traffic",
  "app.analysis.table.rank": "Rank",
  "app.analysis.table.search-keyword": "Keyword",
  "app.analysis.table.users": "Users",
  "app.analysis.table.weekly-range": "Weekly Range",
};
