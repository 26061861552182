export default {
  "menu.home": "首頁",
  "menu.dashboard": "Dashboard",
  "menu.dashboard.analysis": "分析頁",
  "menu.dashboard.monitor": "監控頁",
  "menu.dashboard.workplace": "工作臺",
  "menu.form": "表單頁",
  "menu.form.basicform": "基礎表單",
  "menu.form.stepform": "分步表單",
  "menu.form.stepform.info": "分步表單（填寫轉賬信息）",
  "menu.form.stepform.confirm": "分步表單（確認轉賬信息）",
  "menu.form.stepform.result": "分步表單（完成）",
  "menu.form.advancedform": "高級表單",
  "menu.list": "列表頁",
  "menu.list.searchtable": "查詢表格",
  "menu.list.basiclist": "標淮列表",
  "menu.list.cardlist": "卡片列表",
  "menu.list.searchlist": "搜索列表",
  "menu.list.searchlist.articles": "搜索列表（文章）",
  "menu.list.searchlist.projects": "搜索列表（項目）",
  "menu.list.searchlist.applications": "搜索列表（應用）",
  "menu.profile": "詳情頁",
  "menu.profile.basic": "基礎詳情頁",
  "menu.profile.advanced": "高級詳情頁",
  "menu.result": "結果頁",
  "menu.result.success": "成功頁",
  "menu.result.fail": "失敗頁",
  "menu.account": "個人頁",
  "menu.account.center": "個人中心",
  "menu.account.settings": "個人設置",
  "menu.account.trigger": "觸發報錯",
  "menu.account.logout": "退出登錄",
  "menu.exception": "异常页",
  "menu.exception.not-permission": "403",
  "menu.exception.not-find": "404",
  "menu.exception.server-error": "500",
  "menu.exception.trigger": "触发错误",
};
