export default {
  "component.globalHeader.search": "Search",
  "component.globalHeader.search.example1": "Search example 1",
  "component.globalHeader.search.example2": "Search example 2",
  "component.globalHeader.search.example3": "Search example 3",
  "component.globalHeader.help": "Help",
  "component.globalHeader.notification": "Notification",
  "component.globalHeader.notification.empty": "You have viewed all notifications.",
  "component.globalHeader.message": "Message",
  "component.globalHeader.message.empty": "You have viewed all messsages.",
  "component.globalHeader.event": "Event",
  "component.globalHeader.event.empty": "You have viewed all events.",
  "component.noticeIcon.clear": "Clear",
  "component.noticeIcon.cleared": "Cleared",
  "component.noticeIcon.empty": "No notifications",
  "component.noticeIcon.loaded": "Loaded",
  "component.noticeIcon.loading-more": "Loading more",
};
