export default {
  "app.result.error.title": "Submission Failed",
  "app.result.error.description":
    "Please check and modify the following information before resubmitting.",
  "app.result.error.hint-title": "The content you submitted has the following error:",
  "app.result.error.hint-text1": "Your account has been frozen",
  "app.result.error.hint-btn1": "Thaw immediately",
  "app.result.error.hint-text2": "Your account is not yet eligible to apply",
  "app.result.error.hint-btn2": "Upgrade immediately",
  "app.result.error.btn-text": "Return to modify",
  "app.result.success.title": "Submission Success",
  "app.result.success.description":
    "The submission results page is used to feed back the results of a series of operational tasks. If it is a simple operation, use the Message global prompt feedback. This text area can show a simple supplementary explanation. If there is a similar requirement for displaying “documents”, the following gray area can present more complicated content.",
  "app.result.success.operate-title": "Project Name",
  "app.result.success.operate-id": "Project ID：",
  "app.result.success.principal": "Principal：",
  "app.result.success.operate-time": "Effective time：",
  "app.result.success.step1-title": "Create project",
  "app.result.success.step1-operator": "Qu Lili",
  "app.result.success.step2-title": "Departmental preliminary review",
  "app.result.success.step2-operator": "Zhou Maomao",
  "app.result.success.step2-extra": "Urge",
  "app.result.success.step3-title": "Financial review",
  "app.result.success.step4-title": "Finish",
  "app.result.success.btn-return": "Back to list",
  "app.result.success.btn-project": "View project",
  "app.result.success.btn-print": "Print",
};
