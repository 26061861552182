export default {
  "app.analysis.test": "工专路 {no} 号店",
  "app.analysis.introduce": "指标说明",
  "app.analysis.total-sales": "总销售额",
  "app.analysis.day-sales": "日销售额",
  "app.analysis.visits": "访问量",
  "app.analysis.visits-trend": "访问量趋势",
  "app.analysis.visits-ranking": "门店访问量排名",
  "app.analysis.day-visits": "日访问量",
  "app.analysis.week": "周同比",
  "app.analysis.day": "日同比",
  "app.analysis.payments": "支付笔数",
  "app.analysis.conversion-rate": "转化率",
  "app.analysis.operational-effect": "运营活动效果",
  "app.analysis.sales-trend": "销售趋势",
  "app.analysis.sales-ranking": "门店销售额排名",
  "app.analysis.all-year": "全年",
  "app.analysis.all-month": "本月",
  "app.analysis.all-week": "本周",
  "app.analysis.all-day": "今日",
  "app.analysis.search-users": "搜索用户数",
  "app.analysis.per-capita-search": "人均搜索次数",
  "app.analysis.online-top-search": "线上热门搜索",
  "app.analysis.the-proportion-of-sales": "销售额类别占比",
  "app.analysis.channel.all": "全部渠道",
  "app.analysis.channel.online": "线上",
  "app.analysis.channel.stores": "门店",
  "app.analysis.sales": "销售额",
  "app.analysis.traffic": "客流量",
  "app.analysis.table.rank": "排名",
  "app.analysis.table.search-keyword": "搜索关键词",
  "app.analysis.table.users": "用户数",
  "app.analysis.table.weekly-range": "周涨幅",
};
