export default {
  "app.result.error.title": "提交失敗",
  "app.result.error.description": "請核對並修改以下信息後，再重新提交。",
  "app.result.error.hint-title": "您提交的內容有如下錯誤：",
  "app.result.error.hint-text1": "您的賬戶已被凍結",
  "app.result.error.hint-btn1": "立即解凍",
  "app.result.error.hint-text2": "您的賬戶還不具備申請資格",
  "app.result.error.hint-btn2": "立即升級",
  "app.result.error.btn-text": "返回修改",
  "app.result.success.title": "提交成功",
  "app.result.success.description":
    "提交結果頁用於反饋壹系列操作任務的處理結果， 如果僅是簡單操作，使用 Message 全局提示反饋即可。 本文字區域可以展示簡單的補充說明，如果有類似展示 “單據”的需求，下面這個灰色區域可以呈現比較復雜的內容。",
  "app.result.success.operate-title": "項目名稱",
  "app.result.success.operate-id": "項目 ID：",
  "app.result.success.principal": "負責人：",
  "app.result.success.operate-time": "生效時間：",
  "app.result.success.step1-title": "創建項目",
  "app.result.success.step1-operator": "曲麗麗",
  "app.result.success.step2-title": "部門初審",
  "app.result.success.step2-operator": "周毛毛",
  "app.result.success.step2-extra": "催壹下",
  "app.result.success.step3-title": "財務復核",
  "app.result.success.step4-title": "完成",
  "app.result.success.btn-return": "返回列表",
  "app.result.success.btn-project": "查看項目",
  "app.result.success.btn-print": "打印",
};
