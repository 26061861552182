export default {
  "app.monitor.trading-activity": "活動實時交易情況",
  "app.monitor.total-transactions": "今日交易總額",
  "app.monitor.sales-target": "銷售目標完成率",
  "app.monitor.remaining-time": "活動剩余時間",
  "app.monitor.total-transactions-per-second": "每秒交易總額",
  "app.monitor.activity-forecast": "活動情況預測",
  "app.monitor.efficiency": "券核效率",
  "app.monitor.ratio": "跳出率",
  "app.monitor.proportion-per-category": "各品類占比",
  "app.monitor.fast-food": "中式快餐",
  "app.monitor.western-food": "西餐",
  "app.monitor.hot-pot": "火鍋",
  "app.monitor.waiting-for-implementation": "Waiting for implementation",
  "app.monitor.popular-searches": "熱門搜索",
  "app.monitor.resource-surplus": "資源剩余",
  "app.monitor.fund-surplus": "補貼資金剩余",
};
